import React, { Component } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { datePickerFormat } from '../../services/ValidationRules'
import ListMUITextField from './ListMUITextField'

const apiDateFilterFormat = 'MM/DD/YYYY'

class DatePickerValidate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.defaultValue,
      errorMsg: ''
    }
    this.forwardChange = _.debounce(this.props.onChange.bind(this), 1000)
  }

  validate (value) {
    let errorMsg = null
    if (_.size(value)) {
      const stringInMoment = moment(value, datePickerFormat, true)
      if (stringInMoment.isValid()) {
        this.forwardChange(stringInMoment.format(apiDateFilterFormat))
      }
      errorMsg = moment(value, datePickerFormat, true).isValid() ? null : 'Incorrect Format'
    } else {
      this.forwardChange('')
    }
    this.setState({ value, errorMsg })
  }

  render () {
    return (
      <ListMUITextField
        errorText={this.state.errorMsg}
        hint={datePickerFormat}
        value={this.state.value}
        onChange={event => this.validate(event.target.value)}
      />
    )
  }
}

export default DatePickerValidate
