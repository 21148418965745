import React from 'react'
import { Link } from 'react-router-dom'
import { NavbarWrap, Logo, Links } from './style'

export const getPathDestination = (path) => {
  return path ? path.split('/').length > 0 && path.split('/').pop() : '/'
}

export const linkClassName = testPath =>
  getPathDestination(window.location.pathname) === testPath ? 'current' : ''

const Navbar = props => (
  <header>
    <NavbarWrap>
      <Logo as={Link} to='/'>Device&nbsp;Registry</Logo>
      <Links>
        <Link to='/' className={linkClassName('')}>
          Chargers
        </Link>

        <Link to='/Customers' className={linkClassName('Customers')}>
          Customers
        </Link>

        <Link to='/Environments' className={linkClassName('Environments')}>
          Environments
        </Link>

        <Link to='/History' className={linkClassName('History')}>
          History
        </Link>

        <Link
          to='/FirmwareManagementSystem'
          className={linkClassName('FirmwareManagementSystem')}>
          FMS Servers
        </Link>

        <Link
          to='/fms-flashing-parameters'
          className={linkClassName('fms-flashing-parameters')}>
          FMS Flashing Parameters
        </Link>

        <a href='#logout' className='logout' onClick={props.logout}>
          Sign Out
        </a>
      </Links>
    </NavbarWrap>
  </header>
)

export default Navbar
