import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

export const FormControlWrap = styled(FormControl)`
  &.MuiFormControl-root {
    margin-bottom: 15px; 
  }
  
  &.MuiFormLabel-root {
    color: #5B5B5B;
  }
  
  & .MuiSelect-select:focus {
    background: transparent;
  }
  
`

export const TextFieldWrap = styled(TextField)`
  &.MuiFormControl-root {
    margin-bottom: 15px;
  }
`
