const initState = {
  storedDictionaries: [],
  dictionariesPagination: {},
  isFetching: true
}

function dictionaries (state = initState, action) {
  switch (action.type) {
    case 'CLEAR_STATE':
      return initState
    case 'DICTIONARY_LIST_REQUEST':
      return Object.assign({}, state, { isFetching: action.isFetching })
    case 'INIT_DICTIONARY':
      return Object.assign({}, state, { [action.name]: action.dictionary })
    case 'SAVE_DICTIONARY_PAGINATION':
      return Object.assign({}, state,
        {
          dictionariesPagination: Object.assign({}, state.dictionariesPagination, { [action.name]: action.paginationObj })
        }
      )
    case 'STORE_DICTIONARIES':
      return Object.assign({}, state, { storedDictionaries: { ...state.storedDictionaries, ...action.dictionaries } })
    default:
      return state
  }
}

export default dictionaries
