import React from 'react'
import { TextFieldWrap } from '../TextField/style'
import { v4 } from 'uuid'

const ListMUITextField = (props) => (
  <TextFieldWrap
    variant='outlined'
    value={props.value}
    error={!!props.errorText}
    defaultValue={props.defaultValue}
    onChange={event => props.onChange(event)}
    helperText={props.errorText || props.hint}
    id={v4()}
    fullWidth
  />
)

export default ListMUITextField
