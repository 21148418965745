import { connect } from 'react-redux'
import Navbar from '../components/Navbar/Navbar'
import { logout } from '../actions/login'

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
