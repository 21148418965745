import * as _ from 'lodash'

// to save state to localstorage at least once in second
export const throttleInterval = 1000

const appStorePrefix = 'device_registry_app_store'
const STORE_KEY = appStorePrefix
const pathsToExcludeFromSaving = []

const clearOldStores = () => {
  const allStorageKeys = Object.keys(localStorage)
  if (_.size(allStorageKeys)) {
    for (const key of allStorageKeys) {
      if (key.includes(appStorePrefix) && key !== STORE_KEY) {
        localStorage.removeItem(key)
      }
    }
  }
}

export const loadStore = () => {
  try {
    const state = localStorage.getItem(STORE_KEY)
    clearOldStores()
    return JSON.parse(state) || {}
  } catch (err) {
    return undefined
  }
}

export const saveStore = state => {
  try {
    const serialized = JSON.stringify(_.omit(state, pathsToExcludeFromSaving))
    localStorage.setItem(STORE_KEY, serialized)
  } catch (err) {
    console.error(err)
  }
}
