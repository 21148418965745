import React, { Component } from 'react'
import { Button as RaisedButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

class Page404 extends Component {
  render () {
    return (
      <div className='page404-wrapper'>
        <div className='page404-message'>
          <div className='page404-message-bg'>404</div>
          <div className='page404-message-text'>Oops... Looks like you are lost!</div>
        </div>
        <Link to='/'><RaisedButton label='Back Home' /></Link>
      </div>
    )
  }
}

export default Page404
