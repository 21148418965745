import { chargersListDefaultSorting } from '../components/boardTable/BoardsList'
import * as _ from 'lodash'
const initListState = {
  isFetching: false,
  forceUpdate: false,
  page_token: 0,
  page_size: 10,
  filter: null,
  sorting: null,
  totalCount: 0,
  data: [],
  selectedDataIds: [],
  selectedChipIds: [],
  selectAll: false,
  isExporting: false
}

function ssplists (state = {}, action) {
  switch (action.type) {
    case 'CLEAR_STATE':
      return {}
    case 'INIT_SSPLIST': {
      // TODO: refactor later!!!
      if (!state[action.id]) {
        return Object.assign({}, state, {
          [action.id]: {
            ...initListState,
            page_size: action.defaultPageSize,
            sorting: action.defaultSorting,
            forceUpdate: true
          }
        })
      }

      const sspListInitState = {
        ...state[action.id],
        page_size: action.defaultPageSize,
        forceUpdate: true,
        isExporting: false
      }
      if (!state[action.id].sorting) {
        sspListInitState.sorting = action.defaultSorting
      }
      return Object.assign({}, state, { [action.id]: sspListInitState })
    }
    case 'LIST_REQUEST':
      return Object.assign({}, state, { [action.id]: { ...state[action.id], isFetching: true, forceUpdate: false, data: [] } })
    case 'LIST_REQUEST_SUCCESS':
      return Object.assign({}, state, {
        [action.id]: {
          ...state[action.id],
          isFetching: false,
          data: action.data,
          forceUpdate: false
        }
      })
    case 'LIST_REQUEST_ERROR':
      return Object.assign({}, state, {
        [action.id]: {
          ...state[action.id],
          isFetching: false,
          error: action.error,
          forceUpdate: false
        }
      })
    case 'CHANGE_PAGE_OR_SIZE':
      return Object.assign({}, state, {
        [action.id]: {
          ...state[action.id],
          page_token: action.page_token,
          page_size: action.page_size
        }
      })
    case 'CHANGE_FILTER':
      return Object.assign({}, state, { [action.id]: { ...state[action.id], filter: action.filter, page_token: 0 } })
    case 'CHANGE_SORTING':
      return Object.assign({}, state, { [action.id]: { ...state[action.id], sorting: action.sorting, page_token: 0 } })
    case '@@router/LOCATION_CHANGE': {
      const pathname = action.payload.pathname
      const stateToModify = {}
      if (!pathname.includes('chargers')) {
        if (state.chargersList) {
          stateToModify.chargersList = {
            ...state.chargersList,
            sorting: chargersListDefaultSorting,
            filter: null
          }
        }
      }
      return _.size(stateToModify) ? Object.assign({}, state, stateToModify) : state
    }
    case 'SET_TOTAL_COUNT':
      return Object.assign({}, state, { [action.id]: { ...state[action.id], totalCount: action.count } })
    case 'SET_FORCE_UPDATE':
      return Object.assign({}, state, { [action.id]: { ...state[action.id], forceUpdate: true } })
    case 'SSP_TOGGLE_SELECT_ALL': {
      const selectAll = !state[action.id].selectAll
      return Object.assign({}, state, {
        [action.id]: {
          ...state[action.id],
          selectAll,
          selectedDataIds: selectAll ? state[action.id].selectedDataIds : [],
          selectedChipIds: selectAll ? state[action.id].selectedChipIds : []
        }
      })
    }
    case 'SSP_DESELECT_ALL':
      return Object.assign({}, state, {
        [action.id]: {
          ...state[action.id],
          selectAll: false,
          selectedDataIds: [],
          selectedChipIds: []
        }
      })
    case 'SSP_SET_ACTIVE_ITEM': {
      const deviceId = action.itemId
      const stateDeviceIds = state[action.id].selectedDataIds
      const selected = stateDeviceIds.includes(deviceId)
      const deviceIds = selected ? stateDeviceIds.filter(id => id !== deviceId) : [...stateDeviceIds, deviceId]

      const chipId = action.chipId
      const stateChipIds = state[action.id].selectedChipIds
      const selectedUuid = stateChipIds.includes(chipId)
      const deviceChipIds = selectedUuid ? stateChipIds.filter(id => id !== chipId) : [...stateChipIds, chipId]

      return Object.assign({}, state, {
        [action.id]: {
          ...state[action.id], selectedDataIds: deviceIds, selectedChipIds: deviceChipIds
        }
      })
    }
    case 'RECALCULATE_SORT_FILTER_WITH_COLUMNS': {
      const columns = action.columns
      columns.unshift('c_uuid')
      const stateById = state[action.id]
      const sortingToReturn = columns.includes(_.get(stateById, 'sorting.id'))
        ? stateById.sorting
        : { id: columns[0], desc: false }
      const filterToReturn = _.size(stateById.filter)
        ? stateById.filter.map((field) => columns.includes(field.id) ? field : null).filter((field) => !!field)
        : []
      return Object.assign({}, state, {
        [action.id]: {
          ...state[[action.id]],
          sorting: sortingToReturn,
          filter: filterToReturn,
          forceUpdate: true
        }
      })
    }
    case 'SET_LIST_EXPORTING': {
      const { payload: { id, isExporting } } = action
      return { ...state, [id]: { ...state[id], isExporting } }
    }
    default:
      return state
  }
}

export default ssplists
