import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'
import Boards from './Boards'
import ssplists from './ssplist'
import asyncState from './asyncState'
import dictionaries from './dictionaries'
import login from './login'

const devRegistryApp = combineReducers({
  Boards,
  asyncState,
  ssplists,
  login,
  router: routerReducer,
  form: formReducer,
  dictionaries
})

export default devRegistryApp
