import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loader from './Loader'
import Page404 from './Page404'

const Boards = lazy(() =>
  import(/* webpackChunkName: "BoardsList" */ '../containers/Boards'))

const Board = lazy(() =>
  import(/* webpackChunkName: "Board" */ '../components/Board'))

const Customers = lazy(() =>
  import(/* webpackChunkName: "Customers" */ '../containers/Customers'))

const Environments = lazy(() =>
  import(/* webpackChunkName: "Environments" */ '../containers/Environments'))

const History = lazy(() =>
  import(/* webpackChunkName: "History" */ '../containers/History'))

const FirmwareManagementSystem = lazy(() =>
  import(/* webpackChunkName: "FirmwareManagementSystem" */ '../containers/FirmwareManagementSystem'))

const FMSFlashingParameters = lazy(() =>
  import(/* webpackChunkName: "FMSFlashingParameters" */ '../containers/FMSFlashingParameters'))

const Routes = () => (
  <Switch>
    <Redirect from='/index.html' to='/' />
    <Route exact path='/'>
      <Suspense fallback={<Loader />}>
        <Boards />
      </Suspense>
    </Route>

    <Route exact path='/charger/:id'>
      <Suspense fallback={<Loader />}>
        <Board />
      </Suspense>
    </Route>

    <Route exact path='/Customers'>
      <Suspense fallback={<Loader />}>
        <Customers />
      </Suspense>
    </Route>

    <Route exact path='/Environments'>
      <Suspense fallback={<Loader />}>
        <Environments />
      </Suspense>
    </Route>

    <Route exact path='/History'>
      <Suspense fallback={<Loader />}>
        <History />
      </Suspense>
    </Route>

    <Route exact path='/FirmwareManagementSystem'>
      <Suspense fallback={<Loader />}>
        <FirmwareManagementSystem />
      </Suspense>
    </Route>

    <Route exact path='/fms-flashing-parameters'>
      <Suspense fallback={<Loader />}>
        <FMSFlashingParameters />
      </Suspense>
    </Route>

    <Route component={Page404} />
  </Switch>
)

export default Routes
