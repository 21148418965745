import React from 'react'
import { DateRangePicker } from 'react-dates'
import isAfterDay from 'react-dates/lib/utils/isAfterDay'
import moment from 'moment'

class RenderRangePicker extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      startDate: null,
      endDate: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleFocusedInput = this.handleFocusedInput.bind(this)
  }

  handleChange (obj) {
    this.setState(obj)
    this.props.input.onChange(obj)
  }

  handleFocusedInput (focusedInput) {
    this.setState({ focusedInput })
  }

  render () {
    const { className, pickerProps } = this.props
    return (
      <div className={className}>
        <DateRangePicker
          showClearDates
          isOutsideRange={day => isAfterDay(day, moment())}
          hideKeyboardShortcutsPanel
          verticalSpacing={11}
          firstDayOfWeek={1}
          startDateId='start_date_id'
          endDateId='end_date_id'
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onDatesChange={this.handleChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={this.handleFocusedInput}

          {...pickerProps}
        />
      </div>
    )
  }
}
export default RenderRangePicker
