const defaultState = {
  activeChargerIds: [],
  uploadMessage: null,
  showUploadResultDialog: false,
  isImportFetching: false,
  importProgress: {},
  viewingBoard: null
}

const BoardsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'REFRESH_TABLE':
      return state
    case 'SET_VIEWING_BOARD':
      return { ...state, viewingBoard: action.payload }
    case 'SET_ACTIVE_CHARGER':
      return Object.assign({}, state, {
        activeChargerIds:
          state.activeChargerIds.indexOf(action.id) === -1
            ? [...state.activeChargerIds, action.id]
            : state.activeChargerIds.filter((id) => { return id !== action.id })
      })
    case 'TOGGLE_DIALOG':
      return Object.assign({}, state, { showUploadResultDialog: action.flag })
    case 'SET_DIALOG_MESSAGE':
      return Object.assign({}, state, { uploadMessage: action.msg })
    case 'TOGGLE_IMPORT_FETCHING':
      return Object.assign({}, state, { isImportFetching: action.flag })
    case 'UPDATE_IMPORT_PROGRESS':
      return Object.assign({}, state, { importProgress: action.progress })
    default:
      return state
  }
}

export default BoardsReducer
