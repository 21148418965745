import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import moment from 'moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateUtils from '@date-io/moment'
import IconButton from '@material-ui/core/IconButton'
import ContentClear from '@material-ui/icons/Clear'
import CalendarIcon from '@material-ui/icons/Event'
import { datePickerFormat } from '../../services/ValidationRules'
import { TextFieldWrap } from '../TextField/style'
import InputAdornment from '@material-ui/core/InputAdornment'

class RenderDatepickerFilter extends React.PureComponent {
  constructor (props) {
    super(props)
    this.datePickerFormat = this.datePickerFormat.bind(this)
    this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this)
    this.handleDateInputChange = this.handleDateInputChange.bind(this)
    this.saveRef = this.saveRef.bind(this)
    this.focusDatePicker = this.focusDatePicker.bind(this)
    this.forwardChange = _.debounce(this.onChange, 500)

    this.state = {
      localValue: props.value || ''
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.value !== nextProps.value && !this.props.timestampOutput) {
      this.setState({ localValue: nextProps.value })
    }
  }

  onChange (value) {
    this.props.onChange(value)
  }

  handleChangeDatePicker = (date) => {
    const formattedDate = moment(date).format(datePickerFormat)
    this.setState({ localValue: formattedDate })
    if (this.props.timestampOutput) {
      this.onChange((moment.utc(formattedDate, datePickerFormat, true).valueOf()))
    } else {
      this.onChange(formattedDate)
    }
  }

  handleDateInputChange = (event, text) => {
    this.setState({ localValue: text })
    if (moment(text, datePickerFormat, true).isValid()) {
      if (this.props.timestampOutput) {
        this.forwardChange(moment.utc(text, datePickerFormat, true).valueOf())
      } else {
        this.forwardChange(text)
      }
    } else if (text === '') {
      this.forwardChange('')
    }
  }

  clearDatePicker = (event) => {
    event.stopPropagation()
    this.handleDateInputChange(null, '')
  }

  saveRef (ref) {
    this.datePicker = ref
  }

  datePickerFormat (date) {
    return moment(date).format(datePickerFormat)
  }

  focusDatePicker () {
    this.datePicker.focus()
  }

  convertInputToDate (localValue) {
    let datePickerDate = null
    if (typeof localValue === 'string') {
      const data = moment(localValue, datePickerFormat, true)
      if (data.isValid()) {
        datePickerDate = moment(localValue, datePickerFormat).toDate()
      }
    }

    return datePickerDate
  }

  render () {
    const { localValue } = this.state
    const datePickerDate = this.convertInputToDate(localValue)

    const clearButton = localValue &&
      <IconButton
        style={{ padding: 0 }}
        onClick={this.clearDatePicker} >
        <ContentClear/>
      </IconButton>

    return (
      <MuiPickersUtilsProvider utils={DateUtils}>
        <TextFieldWrap
          fullWidth
          as={DatePicker}
          variant="outlined"
          format={datePickerFormat}
          value={datePickerDate}
          onChange={this.handleChangeDatePicker}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {clearButton}
                <IconButton style={{ padding: '0 10px 0 0' }}>
                  <CalendarIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

RenderDatepickerFilter.propTypes = {
  value: PropTypes.any,
  timestampOutput: PropTypes.bool,
  onChange: PropTypes.func
}

export default RenderDatepickerFilter
