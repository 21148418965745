import APIClient from '../services/APIClient'
import fileDownload from 'js-file-download'
import moment from 'moment'

export const init = (id, defaultPageSize, defaultSorting) => ({
  type: 'INIT_SSPLIST',
  id,
  defaultPageSize,
  defaultSorting
})

export const listRequest = id => ({
  type: 'LIST_REQUEST',
  id
})

export const listRequestSuccess = (id, data) => ({
  type: 'LIST_REQUEST_SUCCESS',
  id,
  data
})

export const listRequestError = (id, error) => ({
  type: 'LIST_REQUEST_ERROR',
  id,
  error
})

export const changePageOrSize = (id, pageToken, pageSize) => ({
  type: 'CHANGE_PAGE_OR_SIZE',
  id,
  page_token: pageToken,
  page_size: pageSize
})

export const changeFilter = (id, filter) => ({
  type: 'CHANGE_FILTER',
  id,
  filter
})

export const changeSorting = (id, sorting) => ({
  type: 'CHANGE_SORTING',
  id,
  sorting
})

export const setTotalCount = (id, count) => ({
  type: 'SET_TOTAL_COUNT',
  id,
  count
})

export const setForceUpdate = (id) => ({
  type: 'SET_FORCE_UPDATE',
  id
})

export const recalculateSortFilterWithColumns = (id, columns) => ({
  type: 'RECALCULATE_SORT_FILTER_WITH_COLUMNS',
  id,
  columns
})

export const toggleSelectAll = (id) => ({
  type: 'SSP_TOGGLE_SELECT_ALL',
  id
})

export const deselectAll = (id) => ({
  type: 'SSP_DESELECT_ALL',
  id
})

export const setActiveRow = (id, itemId, chipId) => ({
  type: 'SSP_SET_ACTIVE_ITEM',
  id,
  itemId,
  chipId
})

export const onPageSizeChange = (id, { page_token: pageToken, page_size: pageSize }) => dispatch => {
  dispatch(changePageOrSize(id, pageToken, pageSize))
}

export const onFilterChange = (id, { filter_by: filterBy }) => dispatch => dispatch(changeFilter(id, filterBy))

export const onSortChange = (id, { sorting }) => dispatch => dispatch(changeSorting(id, sorting))

export const setListExporting = (id, isExporting) => ({
  type: 'SET_LIST_EXPORTING',
  payload: { id, isExporting }
})

export const exportToCSV = (exportProps) => () => {
  const { selectedAll, selectedDataIds, page_token, page_size, sorting, filter } = exportProps.lists.chargersList

  const filterBy = {}

  const params = {
    page_token,
    page_size,
    sort_by: (sorting && sorting.id) || 'uuid',
    sort_order: sorting && sorting.desc ? 'DESC' : 'ASC'
  }

  if (!selectedAll) {
    filterBy.ids = selectedDataIds
  }

  if (filter !== null && filter.length > 0) {
    filter.forEach(f => {
      filterBy[f.id] = f.value
    })
  }

  return APIClient.post('/devices/export',
    { filter_by: filterBy },
    { params })
    .then(({ data, headers }) => {
      fileDownload(data, `exported-devices-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`, headers['content-type'])
    }).catch(err => {
      console.error(err)
    })
}
