import styled from 'styled-components'

export const NavbarWrap = styled.div`
  display: flex;
  align-items: center;
  min-height: 75px;
  padding: 0 25px;
  background: linear-gradient(to right, #024d66, #3d9275);
`

export const Logo = styled.div`
  font-size: 25px;
  font-family: Monoton, Roboto, sans-serif;
  color: white;
  text-decoration: none;
`

export const Links = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  padding: 0 0 0 25px;
  justify-content: flex-start;
  a {
    display: inline-block;
    vertical-align: middle;
    line-height: 75px;
    color: #fff;
    height: 100%;
    text-decoration: none;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    padding: 0 16px;
    
    &:last-child {
      padding: 0;
    }
    
    &.current {
      background-color: rgba(153, 153, 153, 0.2);
    }
    
    &.logout {
      margin-left: auto;
       &:hover {
        background-color: transparent;
      } 
    }
    
    &:hover {
      background-color: rgba(153, 153, 153, 0.3);
      color: rgb(255, 255, 255);
    }
  }
`
