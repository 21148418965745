import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { makePrefix } from '../mui-helper'

const inputPrefix = 'MuiInputBase'
const outlinedInputPrefix = 'MuiOutlinedInput'

const base = `& ${makePrefix(inputPrefix, 'root')}`
const input = `& ${makePrefix(inputPrefix, 'input')}`
const outlinedBase = `& ${makePrefix(outlinedInputPrefix, 'root')}`

export const TextFieldWrap = styled(TextField)`
  ${base} {
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    &:before, &.Mui-focused:before, &:after {
      display: none;
    }
  }
  
  ${outlinedBase} {
    &:hover, &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
  
  ${input} {
    font-size: 13px !important;
    padding: 7px !important;
    border: none !important;
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`
