import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import React from 'react'
import { SelectWrap } from '../Select/style'

const defaultSelectMenuProps = {
  variant: 'menu',
  getContentAnchorEl: null,
  style: {
    maxHeight: '400px'
  }
}

const ListMUISelectField = (props) => {
  const { onChange, value, children, hint } = props

  return <FormControl
    variant="outlined"
    size="small"
    fullWidth>
    <SelectWrap
      value={value}
      displayEmpty
      onChange={
        (event) =>
          onChange(event.target.value)
      }
      MenuProps={props.MenuProps || defaultSelectMenuProps}
    >
      {children}
    </SelectWrap>
    <FormHelperText>{hint}</FormHelperText>
  </FormControl>
}

export default ListMUISelectField
