import { connect } from 'react-redux'
import SSPList from '../components/Form/SSPList'
import { init, onFilterChange, onPageSizeChange, onSortChange, setListExporting, exportToCSV } from '../actions/ssplist'

const mapStateToProps = state => ({ lists: state.ssplists })

const mapDispatchToProps = dispatch => ({
  init: (id, defaultPageSize, sorting) => dispatch(init(id, defaultPageSize, sorting)),
  onPageSizeChange: (id, opts) => dispatch(onPageSizeChange(id, opts)),
  onFilterChange: (id, opts) => dispatch(onFilterChange(id, opts)),
  onSortChange: (id, opts) => dispatch(onSortChange(id, opts)),
  setExporting: (id, isExporting) => dispatch(setListExporting(id, isExporting)),
  exportToCSV: (exportProps) => dispatch(exportToCSV(exportProps))
})

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, {
    data: stateProps.lists[ownProps.id] ? stateProps.lists[ownProps.id] : [],
    isFetching: stateProps.lists[ownProps.id] ? stateProps.lists[ownProps.id].isFetching : false,
    forceUpdate: stateProps.lists[ownProps.id] ? stateProps.lists[ownProps.id].forceUpdate : false,
    init: (opts) => dispatchProps.init(ownProps.id, opts.page_size, ownProps.defaultSorted),
    onPageSizeChange: (opts) => dispatchProps.onPageSizeChange(ownProps.id, opts),
    onFilterChange: (opts) => dispatchProps.onFilterChange(ownProps.id, opts),
    onSortChange: (opts) => dispatchProps.onSortChange(ownProps.id, opts),
    exportTable: () => {
      dispatchProps.setExporting(ownProps.id, true)
      const result = dispatchProps.exportToCSV(stateProps)

      if (result) {
        result.finally(
          () => {
            dispatchProps.setExporting(ownProps.id, false)
          }
        )
      } else {
        dispatchProps.setExporting(ownProps.id, false)
      }
    },
    isExporting: stateProps.lists[ownProps.id] ? stateProps.lists[ownProps.id].isExporting : false
  })
)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SSPList)
