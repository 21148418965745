import { REACT_APP_CLIENT_ID, REACT_HOST } from '../config'
import axios from 'axios'
import * as _ from 'lodash'
import jwtDecode from 'jwt-decode'
import { throttleInterval } from '../services/LocalStorage'

const getOrigin = () => {
  return process.env.NODE_ENV !== 'production'
    ? 'https://dev.device-registry.i2p2.iotecha.com'
    : window.location.origin
}

const goToOrigin = _.debounce(() => { window.location.replace(`${window.location.origin}`) }, throttleInterval)

export const clear = () => ({
  type: 'CLEAR_STATE'
})

export const saveUserAndIdToken = (idToken, user) => ({
  type: 'SAVE_USER_AND_ID_TOKEN',
  id_token: idToken,
  user
})

export const setTokenExpirationIntervalId = id => ({
  type: 'SET_TOKEN_EXPIRATION_INTERVAL_ID',
  id
})

export const loginSuccess = (idToken, refreshToken, user) => ({
  type: 'LOGIN_SUCCESS',
  id_token: idToken,
  refresh_token: refreshToken,
  user
})

export const checkTokenExpiration = () => ({
  type: 'CHECK_TOKEN_EXPIRATION'
})

export const clearTokenRefreshTimeout = () => ({
  type: 'CLEAR_TOKEN_REFRESH_TIMEOUT'
})

export const tokenExpirationCheck = timestamp => ({
  type: 'TOKEN_EXPIRATION_CHECK',
  timestamp
})

export const login = code => dispatch => {
  const origin = getOrigin()

  axios.post(`${getUrlWithAuthSubDomain(origin)}/oauth2/token`,
    filterToQueryString({
      grant_type: 'authorization_code',
      client_id: REACT_APP_CLIENT_ID,
      code: code,
      redirect_uri: REACT_HOST || origin
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((resp) => {
      const idToken = _.get(resp, 'data.id_token')
      const refreshToken = _.get(resp, 'data.refresh_token')
      const user = jwtDecode(idToken)

      dispatch(loginSuccess(`Bearer ${idToken}`, `${refreshToken}`, user))
      goToOrigin()
    })
    .catch(err => {
      console.log('Login failed:', err)
      dispatch(logout())
    })
}

export const loginFailed = () => dispatch => {
  dispatch(clear())
  const origin = getOrigin()
  const url = getUrlWithAuthSubDomain(origin)

  window.location.replace(`${url}/login?response_type=token&client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${origin}/`)
}

export const logout = () => dispatch => {
  dispatch({ type: 'LOGOUT' })
  dispatch(clear())

  const origin = getOrigin()
  const url = getUrlWithAuthSubDomain(origin)
  const redirectUri = REACT_HOST || origin

  window.location.replace(`${url}/oauth2/authorize?response_type=code&client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${redirectUri}&identity_provider=Google/`)
}

export const refreshToken = (state) => dispatch => {
  const origin = getOrigin()
  const refreshToken = state.login.refreshToken
  delete axios.defaults.headers.common.Authorization

  axios.post(`${getUrlWithAuthSubDomain(origin)}/oauth2/token`,
    filterToQueryString({
      grant_type: 'refresh_token',
      client_id: REACT_APP_CLIENT_ID,
      refresh_token: refreshToken
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((resp) => {
      const idToken = _.get(resp, 'data.id_token')
      const user = jwtDecode(idToken)

      dispatch(saveUserAndIdToken(`Bearer ${idToken}`, user))
    })
    .catch(err => {
      console.log('Token refresh failed:', err)
      dispatch(logout())
    })
}

const getUrlWithAuthSubDomain = sourceUrl => sourceUrl.replace(/^https:\/\//, 'https://auth.')

export const filterToQueryString = (filterObj, overrides) => {
  const copy = Object.assign(overrides || {}, filterObj)
  const searchParams = new URLSearchParams()
  Object.entries(copy).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length) {
      value.forEach(v => searchParams.append(key, v))
    } else if (value) {
      searchParams.set(key, value)
    }
  })
  searchParams.sort()
  return searchParams.toString()
}
