import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import SelectField from '@material-ui/core/Select'
import classnames from 'classnames'
import HardwareKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import HardwareKeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import PropTypes from 'prop-types'
import { FormControlWrap } from './style'
import { ActionButton } from '../Button/style'
import { withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppIcon from '@material-ui/icons/GetApp'

const Loading = withStyles({
  root: {
    color: '#777'
  }
})(CircularProgress)
const exportButtonStyle = { marginLeft: '10px', marginBottom: 2, paddingTop: 4, paddingBottom: 4 }
const exportButtonIconStyle = { marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }

class ListPagination extends Component {
  constructor (props) {
    super()

    this.getSafePage = this.getSafePage.bind(this)
    this.changePage = this.changePage.bind(this)
    this.applyPage = this.applyPage.bind(this)

    this.state = {
      page: props.page
    }
    this.isMobile = window.matchMedia('(max-device-width: 1224px)').matches
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ page: nextProps.page })
  }

  getSafePage (page) {
    if (isNaN(page)) {
      page = this.props.page
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1)
  }

  changePage (page) {
    page = this.getSafePage(page)
    this.setState({ page })
    if (this.props.page !== page) {
      this.props.onPageChange(page)
    }
  }

  applyPage (e) {
    e && e.preventDefault()
    const page = this.state.page
    this.changePage(page === '' ? this.props.page : page)
  }

  render () {
    const {
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      paginationStyle,
      dashboardMobile,
      totalCount,
      isExporting,
      selectAll,
      selectedDataIds
    } = this.props

    const itemFrom = totalCount ? page * pageSize + 1 : 0
    const itemTo = Math.min(totalCount, (page + 1) * pageSize)
    const allItems = totalCount
    const prevPage = page - 1
    const nextPage = page + 1
    const disableExportButton = !(
      (selectAll ||
      (selectedDataIds && selectedDataIds.length > 0)) && this.props.data.length > 0
    ) || isExporting

    return (
      <div className={classnames(className, `-pagination ${dashboardMobile ? 'dashboard-customized' : ''}`)} style={paginationStyle}>
        {showPageSizeOptions && (
          <span className='-pageSizeOptions'>
            {!dashboardMobile && <React.Fragment>Rows per page:&nbsp;</React.Fragment>}
            <FormControlWrap>
              <SelectField
                value={pageSize}
                onChange={
                  (event) =>
                    onPageSizeChange(Number(event.target.value))
                }
                style={{ minWidth: 65 }}
              >
                {pageSizeOptions.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option}>{option}</MenuItem>
                  )
                })}
              </SelectField>
            </FormControlWrap>
            {this.props.exportable &&
              <ActionButton
                variant='contained'
                style={exportButtonStyle}
                disabled={disableExportButton}
                onClick={this.props.onExportTable}>
                <span style={exportButtonIconStyle}>
                  {isExporting ? <Loading size={20} /> : <GetAppIcon />}
                </span>
                {this.isMobile ? '' : 'Export'}
              </ActionButton>
            }
          </span>
        )}
        <div className='-pageJumpBlock'>
          {
            !dashboardMobile &&
            <span className=''>
              Showing
            </span>
          }

          <div className=''>
            <IconButton onClick={() => {
              if (!canPrevious) return
              this.changePage(prevPage)
            }} disabled={!canPrevious}
            >
              <HardwareKeyboardArrowLeft />
            </IconButton>
          </div>

          <span className=''>{itemFrom} to {itemTo}</span>&nbsp;

          <div className=''>
            <IconButton onClick={() => {
              if (!canNext) return
              this.changePage(nextPage)
            }} disabled={!canNext}
            >
              <HardwareKeyboardArrowRight />
            </IconButton>
          </div>

          <span>
            of&nbsp;
            <span className=''>{allItems}</span>
          </span>
        </div>
      </div>
    )
  }
}

ListPagination.propTypes = {
  totalCount: PropTypes.number,
  page: PropTypes.number,
  showPageSizeOptions: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  pageSize: PropTypes.number,
  canPrevious: PropTypes.bool,
  canNext: PropTypes.bool,
  onPageSizeChange: PropTypes.func,
  className: PropTypes.string,
  paginationStyle: PropTypes.object,
  dashboardMobile: PropTypes.bool
}

export default ListPagination
