import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import * as _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import 'react-table/react-table.css'
import SSPList from '../../containers/SSPList'

import { LLFormat } from '../../services/ValidationRules'
import { onDatePickerFilterTimeStampOutput, onTextFilter, SelectFilter } from './CommonTableDataFilter'
import { CertificateCell, CheckboxCell } from './TableComponents'

export const chargersListDefaultSorting = { id: 'uuid', desc: false }
export const deviceStates = [
  { name: 'BUILT', label: 'Built' },
  { name: 'IN_PROGRESS', label: 'In Progress' },
  { name: 'ERROR', label: 'Error' },
  { name: 'PENDING', label: 'Pending' },
  { name: 'ACTIVE', label: 'Active' },
  { name: 'INACTIVE', label: 'Inactive' },
  { name: 'NEW', label: 'New' },
  { name: 'AUTO_TRANSFERRED', label: 'Transferring' },
  { name: 'TRANSFERRED', label: 'Transferred' }
]

class BoardsList extends Component {
  render () {
    const originSelectItems = [
      <MenuItem
        key={'all'}
        value="">All</MenuItem>,
      ..._.sortBy(Object.values(deviceStates), 'label')
        .map(item => <MenuItem
          key={item.name}
          value={item.name}>{item.label}</MenuItem>)
    ]

    const columns = [
      {
        id: 'checkbox',
        accessor: '',
        Header: () => (
          <Checkbox
            checked={this.props.selectAll}
            onChange={this.props.toggleSelectAll}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        ),
        Cell: ({ original }) => (
          <div>
            <CheckboxCell
              id={original.id}
              activeChargerIds={this.props.activeChargerIds}
              onRowSelected={this.props.onRowSelected}
              selectAll={this.props.selectAll}
            />
          </div>
        ),
        filterable: false,
        sortable: false,
        minWidth: 40
      }, {
        Header: 'Created Date',
        accessor: 'created_date',
        Filter: onDatePickerFilterTimeStampOutput,
        minWidth: 200,
        Cell: ({ value }) => <div>{value ? moment.utc(value).format(LLFormat) : ''}</div>
      }, {
        Header: 'Flashed Date',
        accessor: 'flashed_date',
        Filter: onDatePickerFilterTimeStampOutput,
        minWidth: 200,
        Cell: ({ value }) => <div>{value ? moment.utc(value).format(LLFormat) : ''}</div>
      }, {
        Header: 'Chip ID',
        accessor: 'uuid',
        minWidth: 200,
        Filter: onTextFilter,
        Cell: ({ original, value: uuid }) => <div><Link to={`/charger/${original.id}`}>{uuid}</Link></div>
      }, {
        Header: 'Customer ID',
        accessor: 'customer_id',
        minWidth: 100,
        Filter: onTextFilter
      }, {
        Header: 'Firmware Version',
        accessor: 'firmware_version', // 'fw_version',
        minWidth: 200,
        Filter: onTextFilter
      }, {
        Header: 'Properties',
        accessor: 'properties',
        minWidth: 200,
        Filter: onTextFilter,
        Cell: ({ original, value }) => {
          const propertyCount = Object.keys(value).length
          const inlineProperties = Object.keys(value).map(key => key + ': ' + value[key]).join('\n')

          return <Link
            to={`/charger/${original.id}`}
            title={inlineProperties}>{propertyCount} properties</Link>
        }
      }, {
        Header: 'State',
        accessor: 'device_state',
        minWidth: 200,
        Cell: ({ value }) => <div>{_.get(deviceStates.find(state => state.name === value), 'label')}</div>,
        Filter: ({ filter, onChange }) => (
          <SelectFilter
            options={originSelectItems}
            onChange={onChange}
            value={filter ? filter.value : ''}
          />
        )
      }, {
        Header: 'Certificates',
        accessor: 'provisioning_certificate',
        minWidth: 200,
        Cell: ({ original }) => (
          <div>
            <CertificateCell
              value={original} />
          </div>),
        filterable: false,
        sortable: false
      }
    ]

    return (
      <SSPList
        onGetDataAction={this.props.getData}
        defaultPageSize={20}
        defaultSorted={chargersListDefaultSorting}
        id="chargersList"
        columns={columns}
        clearFilterOnInit
      />
    )
  }
}

export default (BoardsList)
