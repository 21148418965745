import React, { Component } from 'react'
import ReactTable from 'react-table'
import ListPagination from './ListPagination'
import * as _ from 'lodash'
import * as ReactDOM from 'react-dom'

class SSPList extends Component {
  constructor (props) {
    super(props)
    this.onPageChange = this.onPageChange.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.onFilteredChange = this.onFilteredChange.bind(this)
    this.onSortedChange = this.onSortedChange.bind(this)
    this.scrollTop = this.scrollTop.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.tableComponent = null
  }

  componentDidMount () {
    const storedPageSize = _.get(this.props, 'data.page_size') || this.props.defaultPageSize
    this.props.init({ page_size: storedPageSize })
    window.addEventListener('beforeunload', this.clearFilter)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { forceUpdate, page_token: pageToken, page_size: pageSize, sorting, filter } = nextProps.data
    if (forceUpdate === true) {
      this.getData(nextProps.id, { page_token: pageToken, page_size: pageSize, sorting, filter_by: filter })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.clearFilter)
  }

  clearFilter () {
    const { clearFilterOnInit, defaultSorted } = this.props
    if (clearFilterOnInit) {
      this.props.onFilterChange({ filter_by: null })
      this.props.onSortChange({ sorting: defaultSorted })
    }
  }

  getData (id, opts) {
    if (_.isFunction(this.props.onGetDataAction)) {
      this.props.onGetDataAction(id, opts)
    }
  }

  scrollTop () {
    ReactDOM.findDOMNode(this.tableComponent).getElementsByClassName('rt-table')[0].scrollTop = 0
  }

  onPageChange (pageToken) {
    this.props.onPageSizeChange({ page_token: pageToken, page_size: this.props.data.page_size })
    this.scrollTop()

    this.getData(this.props.id, {
      page_token: pageToken,
      page_size: this.props.data.page_size,
      filter_by: this.props.data.filter,
      sorting: this.props.data.sorting
    })
  }

  onPageSizeChange (pageSize, pageToken) {
    this.props.onPageSizeChange({ page_token: pageToken, page_size: pageSize })
    this.getData(this.props.id, {
      page_token: pageToken,
      page_size: pageSize,
      filter_by: this.props.data.filter,
      sorting: this.props.data.sorting
    })
  }

  onFilteredChange (filter) {
    const filterValid = filter.filter((item) => _.size(item) > 0)
    this.props.onFilterChange({ filter_by: filterValid })
    this.getData(this.props.id, {
      page_token: 0, // this.props.data.page_token,
      page_size: this.props.data.page_size,
      filter_by: filterValid,
      sorting: this.props.data.sorting
    })
  }

  onSortedChange (fields) {
    const sorting = _.head(fields)
    if (sorting) {
      this.props.onSortChange({ sorting })
      this.getData(this.props.id, {
        page_token: 0, // this.props.data.page_token,
        page_size: this.props.data.page_size,
        filter_by: this.props.data.filter,
        sorting: sorting
      })
    }
  }

  renderListPagination = (props) => {
    return (
      <ListPagination
        selectAll={this.props.data.selectAll}
        selectedDataIds={this.props.data.selectedDataIds}
        exportable={this.props.id === 'chargersList'}
        onExportTable={this.props.exportTable}
        isExporting={this.props.isExporting}
        {...props}
      />
    )
  }

  render () {
    const {
      data: {
        data, isFetching, page_size: pageSize, page_token: pageToken, totalCount, sorting, filter
      },
      columns,
      filterable,
      subComponent
    } = this.props

    const pages = Math.ceil(totalCount / pageSize)
    const filterableFlag = _.isNil(filterable) ? true : filterable

    const sortingObj = [_.size(sorting) ? sorting : {}]
    const filterObj = _.size(filter) ? filter : []

    const table =
      <ReactTable
        sorted={sortingObj}
        ref={(ref) => {
          this.tableComponent = ref
        }}
        filtered={filterObj}
        loading={isFetching || false}
        data={data || []}
        page={pageToken || 0}
        pages={pages || 0}
        pageSize={pageSize || 0}
        totalCount={totalCount}
        manual
        columns={columns}
        filterable={filterableFlag}
        PaginationComponent={this.renderListPagination}
        onPageChange={this.onPageChange}
        onPageSizeChange={this.onPageSizeChange}
        onFilteredChange={this.onFilteredChange}
        onSortedChange={this.onSortedChange}
        SubComponent={subComponent}
      />
    return (table)
  }
}

export default SSPList
