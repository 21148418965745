const initState = {
  token: null,
  user: null,
  isAuthenticated: false,
  refreshToken: null,
  tokenExpirationIntervalId: null,
  tokenExpirationLastCheck: null,
  isFetching: false
}

function login (state = initState, action) {
  switch (action.type) {
    case 'CLEAR_STATE':
      return initState
    case 'SAVE_USER_AND_ID_TOKEN':
      return Object.assign({}, state, {
        token: action.id_token,
        user: action.user,
        isAuthenticated: true
      })
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        token: action.id_token,
        refreshToken: action.refresh_token,
        user: action.user,
        isAuthenticated: true
      })
    case 'SAVE_REFRESH_TOKEN':
      return Object.assign({}, state, {
        refreshToken: action.refresh_token
      })
    case 'SET_TOKEN_EXPIRATION_INTERVAL_ID':
      return Object.assign({}, state, {
        tokenExpirationIntervalId: action.id
      })
    case 'TOKEN_EXPIRATION_CHECK':
      return Object.assign({}, state, {
        tokenExpirationLastCheck: action.timestamp
      })
    case 'CLEAR_TOKEN_REFRESH_TIMEOUT':
      return Object.assign({}, state, {
        tokenExpirationLastCheck: null
      })
    case 'LOGOUT':
      return Object.assign({}, state, {
        token: null,
        user: null,
        isAuthenticated: false
      })
    default:
      return state
  }
}

export default login
