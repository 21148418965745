import React from 'react'
import get from 'lodash/get'
import size from 'lodash/size'
import Checkbox from '@material-ui/core/Checkbox'

export const CheckboxCell = ({ id, activeChargerIds, onRowSelected, selectAll }) => (
  <Checkbox
    disabled={selectAll}
    checked={(size(activeChargerIds) ? activeChargerIds.includes(id) : false) || selectAll}
    onChange={() => onRowSelected(id)}
  />
)

export const CertificateCell = ({ value }) => {
  const certificateName = get(value, 'provisioning_certificate.certificate_name')
  const certificateAuthorityName = get(value, 'provisioning_certificate.certificate_authority_name')
  const certificateInfo =
    (certificateName || certificateAuthorityName) &&
    `${certificateName}${certificateName && certificateAuthorityName ? ':' : ''}${certificateAuthorityName}`
  const showInfo = (get(value, 'provisioning_certificate.certificate_link') || certificateName || certificateAuthorityName)

  return showInfo ? (<div><div> {certificateInfo} </div>IoT.ON</div>) : null
}
