const defaultState = {
  isAsyncInProgress: false
}

const AsyncStateReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'TOGGLE_ASYNC_STATE':
      return Object.assign({}, state, { isAsyncInProgress: action.flag })
    default:
      return state
  }
}

export default AsyncStateReducer
