import React from 'react'
import logo from '../../images/iotecha-logo.svg'

const Footer = () => (
  <footer className="footer">
    <div>© 2017 - {new Date().getFullYear()} IoTecha. All rights reserved.</div>
    <div>Powered by<img
      alt="logo"
      src={logo} /></div>
  </footer>
)

export default Footer
