import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { makePrefix } from '../mui-helper'

const buttonPrefix = 'MuiButton'

const root = `&${makePrefix(buttonPrefix, 'root')}`
const label = `& ${makePrefix(buttonPrefix, 'label')}`

export const ActionButton = styled(Button)`
  position: relative;
  text-decoration: none;
  
  & input[type=file] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  ${root} {
    background-color: #fff;
    margin-right: 10px;
  }
  
  ${label} {
    text-transform: none;
  }
`

export const ConfirmButton = styled(Button)`
  position: relative;
  text-decoration: none;
  
  & input[type=file] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  ${label} {
    text-transform: none;
  }
`

export const CancelButton = styled(Button)`
  position: relative;
  text-decoration: none;
  
  & input[type=file] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  ${root} {
    background-color: #fff;
    margin-right: 10px;
  }
  
  ${label} {
    text-transform: none;
  }
`
