import moment from 'moment'
import * as _ from 'lodash'

// needed to make localization working
import 'moment/locale/en-au'
import 'moment/locale/en-ca'
import 'moment/locale/en-gb'
import 'moment/locale/nl'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/ru'

export const required = value => value && value.toString().trim() ? undefined : 'Required'

export const requiredWithFalse = value => ((value && value.toString().trim()) || value === false) ? undefined : 'Required'

export const noSpace = value => value && /\s/.test(value.toString()) ? 'There should not be any spaces' : undefined

export const countryNotEmpty = (value, allValues) => (allValues.c_identity.c_country ? undefined : 'Please select Country first')

export const isPending = value => (value === 'pending' ? 'Please change Charger Condition to Active' : undefined)

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const maxLength15 = maxLength(15)

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined

export const minValue18 = minValue(18)

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const datePickerDate = value => value && (!moment(value, LLLFormat, true).isValid() && typeof value !== 'number')
  ? 'Incorrect Data Format'
  : undefined

export const uniqCName = id => (value, allValues) => {
  const cnames = _.get(allValues, 'c_i2p2_configuration.c_port_names', []).map(name => name && name.toLowerCase())
  const valueLowered = value && value.toLowerCase()
  return value && value !== '' && cnames.includes(valueLowered) && cnames.indexOf(valueLowered) !== id
    ? 'This value already exists. Please try different one.'
    : undefined
}

const locale = window.navigator.userLanguage || window.navigator.language
moment.locale([locale, 'en'])
console.info(`browser locale is ${locale}`)
const currentLocaleData = moment.localeData()
export const datePickerFormat = currentLocaleData.longDateFormat('L')
export const LLFormat = currentLocaleData.longDateFormat('LL')
export const LLLFormat = currentLocaleData.longDateFormat('LLL')
export const durationFormat = 'HH:mm:ss'
