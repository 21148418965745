import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import throttle from 'lodash/throttle'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { cyan } from '@material-ui/core/colors'
import { composeWithDevTools } from 'redux-devtools-extension'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import reducers from './reducers'
import './styles/index.scss'
import Routes from './components/Routes'
import APIClient from './services/APIClient'
import Footer from './components/Footer/Footer'
import { logout, login, checkTokenExpiration } from './actions/login'
import { authMiddleware } from './middleware/authMiddleware'
import Navbar from './containers/Navbar'
import { loadStore, saveStore, throttleInterval } from './services/LocalStorage'

const persistedState = loadStore()

const store = createStore(
  reducers,
  persistedState,
  composeWithDevTools(
    applyMiddleware(thunk, authMiddleware)
  )
)

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: cyan[900]
    },
    secondary: {
      main: cyan[900]
    }
  },
  datePicker: {
    headerColor: cyan[900]
  },
  props: {
    MuiIconButton: {
      padding: 0
    },
    MuiCheckbox: {
      style: {
        padding: 0
      }
    },
    MuiButton: {
      text: {
        textTransform: 'none'
      }
    }
  }
})

const pathParams = {}
window.location.href.replace(/[?&]+([^=&]+)=([^& #]*)/gi, (m, key, value) => {
  pathParams[key] = value
})

const code = pathParams.code
const token = store.getState().login.token

if (!code && !token) {
  store.dispatch(logout())
} else if (code && !token) {
  store.dispatch(login(code))
} else if (token) {
  store.dispatch(checkTokenExpiration())
}

window.onbeforeunload = function () {
  return store.getState().asyncState.isAsyncInProgress ? true : null
}

store.subscribe(
  throttle(
    () => saveStore(store.getState()),
    throttleInterval
  )
)

const logoutStatuses = [403, 502, 503, 401]
const networkError = 'Network Error'

APIClient.interceptors.response.use(response => {
  return response
}, error => {
  if ((error.message === networkError || error.response) && logoutStatuses.includes(error.response.status)) {
    store.dispatch(logout())
  }
  return Promise.reject(error)
})

ReactDOM.render(
  token &&
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <Router>
        <div id='root-child'>
          <Navbar />
          <div id='content' >
            <Routes/>
          </div>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
