import React from 'react'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import ContentClear from '@material-ui/icons/Clear'
import ListMUITextField from './ListMUITextField'
import DatePickerValidate from './DatePickerValidate'
import RenderDatepickerFilter from './RenderDatePickerFilter'
import ListMUISelectField from './ListMUISelectField'
import RenderRangePicker from '../Form/RenderRangePicker'
import { datePickerFormat } from '../../services/ValidationRules'

export const onSeparatedTextFilter = ({ filter, onChange }) => {
  const forwardChange = debounce(onChange, 500)
  const filterVal = get(filter, 'value')

  return (
    <ListMUITextField
      defaultValue={filterVal}
      onChange={
        ({ target: { value } }) =>
          value
            ? forwardChange(value.split(',').map(item => item.trim()))
            : forwardChange('')
      }
    />
  )
}

export const onTextFilter = ({ filter, onChange }) => {
  const forwardChange = debounce(onChange, 500)
  const filterVal = get(filter, 'value')
  return (
    <ListMUITextField
      defaultValue={filterVal}
      onChange={(e) => forwardChange(e.target.value)}
    />
  )
}

export const onDataFilterSSP = ({ filter, onChange }) => {
  const filterVal = get(filter, 'value')
  return (
    <DatePickerValidate
      defaultValue={filterVal}
      filter={filter}
      onChange={onChange}
    />
  )
}

export const onDatePickerFilterTimeStampOutput = ({ filter, onChange }) => (
  <RenderDatepickerFilter
    onChange={onChange}
    timestampOutput
    value={filter ? filter.value : ''}
  />
)

export const onFilterDateRangePicker = ({ onChange }) => (
  <RenderRangePicker
    input={{
      onChange: (val) => onChange({
        start_timestamp: val.startDate ? val.startDate.utc().startOf('day').valueOf() : '',
        end_timestamp: val.endDate ? val.endDate.utc().endOf('day').valueOf() : ''
      })
    }}
    className='filter-picker'
    pickerProps={{
      displayFormat: datePickerFormat,
      anchorDirection: 'right',
      appendToBody: true,
      readOnly: true,
      block: true,
      customCloseIcon: <ContentClear />,
      small: true,
      minimumNights: 0
    }}
  />
)

export const SelectFilter = ({ onChange, value, options }) => (
  <ListMUISelectField
    onChange={value => {
      onChange(value)
    }}
    value={value}
    children={options}
  />
)
