import * as axios from 'axios'
import { logout, refreshToken, setTokenExpirationIntervalId, tokenExpirationCheck } from '../actions/login'

const CHECK_EXPIRATION_TIMEOUT_MINUTES = 60
const CHECK_EXPIRATION_TIMEOUT_MINUTES_HEADROOM = 2
const TOKEN_REFRESH_TIMEOUT_MILLISECONDS = (CHECK_EXPIRATION_TIMEOUT_MINUTES * 60 * 1000) - (CHECK_EXPIRATION_TIMEOUT_MINUTES_HEADROOM * 60 * 1000)

const skipActions = [
  'SET_TOKEN_EXPIRATION_INTERVAL_ID',
  'TOKEN_EXPIRATION_CHECK',
  'REFRESH_TOKEN_REQUEST',
  'REFRESH_TOKEN_REQUEST_SUCCESS',
  'REFRESH_TOKEN_REQUEST_ERROR',
  'LOGIN_REQUEST',
  'LOGIN_SUCCESS',
  'LOGIN_ERROR',
  'TOGGLE_TOKEN_FETCHING',
  'SAVE_REFRESH_TOKEN',
  'SAVE_USER_AND_ID_TOKEN',
  'LOGOUT'
]

export const authMiddleware = store => next => action => {
  processTokenExpirationChecks(action, store)
  setTokenHeader(store)
  return next(action)
}

const setTokenHeader = store => {
  const state = store.getState()
  if (state.login.token) {
    axios.defaults.headers.common.Authorization = state.login.token
  } else {
    console.log('Remove auth header')
    delete axios.defaults.headers.common.Authorization
  }
}

const processTokenExpirationChecks = (action, store) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'SAVE_ID_TOKEN':
      setTokenInterval(store)
      break
    case 'LOGOUT':
      clearTokenInterval(store)
      break
    default:
      if (!skipActions.includes(action.type)) {
        const state = store.getState()
        const now = Date.now()

        if (state.login.isAuthenticated) {
          const lastCheckTimedOut = now - state.login.tokenExpirationLastCheck >= TOKEN_REFRESH_TIMEOUT_MILLISECONDS

          if (!state.login.tokenExpirationLastCheck || lastCheckTimedOut) {
            checkTokenExpiration(store)
            setTokenInterval(store)
          }
        }
      }
  }
}

const setTokenInterval = store => {
  clearTokenInterval(store)
  const tokenExpirationIntervalId = setInterval(refreshTokenByTimeout.bind(this, store), TOKEN_REFRESH_TIMEOUT_MILLISECONDS)
  store.dispatch(setTokenExpirationIntervalId(tokenExpirationIntervalId))
}

const clearTokenInterval = store => {
  const state = store.getState()

  if (state.login.tokenExpirationIntervalId) {
    clearInterval(state.login.tokenExpirationIntervalId)
  }
}

const refreshTokenByTimeout = store => {
  store.dispatch(refreshToken(store.getState()))
}

const checkTokenExpiration = store => {
  const state = store.getState()
  const user = state.login.user
  const expirationTime = user.exp * 1000
  const now = Date.now()

  if (expirationTime - now <= 0) {
    store.dispatch(logout())
  } else {
    store.dispatch(refreshToken(state))
  }

  store.dispatch(tokenExpirationCheck(now))
}
